/* DatePicker.css */
.custom-datepicker {
    border: 1px solid #ccc;
    padding: 12px;
    font-family: Arial, sans-serif;
    border-radius: 5px;
  }
  
  .custom-datepicker input {
    border: none;
    outline: none;
    font-size: 14px;
    padding: 5px;
    
  }
  
  .custom-datepicker .react-datepicker__month-container {
    background-color: #f5f5f5;
  }
  
  .custom-datepicker .react-datepicker__day--selected {
    background-color: #007bff;
    color: #fff;
  }
  
  .custom-datepicker .react-datepicker__day--disabled {
    color: #ccc;
  }
  