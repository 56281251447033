.not {
  font-size: 14px;
  color: #000;
}

.signup {
  font-size: 16px;
  text-decoration: none;
  color: #f19e38;
  font-weight: 900;
}
