.css-wdsdfe-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-weight: 700;
    background: #9F3239;
    color: #fff;
    outline: none;
    border-radius: 5px;
    font-family: sans-serif;
    font-size: 16px;
    border-bottom: none;
  }


.css-wdsdfe-MuiButtonBase-root-MuiTab-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-weight: 700;
    line-height: 1.25;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: Public Sans,sans-serif;
    max-width: 360px;
    min-width: 90px;
    position: relative;
    min-height: 48px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 12px 16px;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #637381;
}